.custom-select {
  background-color: transparent;
  border: 0;
  outline: none;
  option {
    color: #000;
  }
}

.accounts-lists {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.yellow {
  color: #ffc626 !important;
}

.red {
  color: #ff2e48 !important;
}

.accounts-list {
  .card-header {
    background: #253944;
    display: flex;
    justify-content: space-between;
    align-items: center;

    .FA-heading {
      font-size: 18px;
      color: #fff;
      font-weight: 500;
    }
  }

  // .card-content {
  //   padding: 15px;

  //   .FA-details {
  //     display: flex;
  //     column-gap: 25px;
  //     flex-wrap: wrap;

  //     @media screen and (max-width: 767px) {
  //       row-gap: 10px;
  //       margin-bottom: 15px;
  //     }
  //   }

  //   // .status-btns {
  //   //   display: flex;
  //   //   flex-direction: column;
  //   //   gap: 3px;

  //   //   @media screen and (max-width: 767px) {
  //   //     flex-direction: row;
  //   //     margin-top: 15px;
  //   //   }
  //   //   .btn {
  //   //     font-size: 14px;
  //   //   }
  //   // }
  // }
}
.consistency {
  position: relative;
}
.consistency-value {
  position: absolute;
  display: flex;
  justify-content: space-around;
  align-items: center;
  width: 100%;
  top: 50%;
  transform: translateY(-50%);
  color: #fff;
  height: 100%;
  .con-value {
    font-size: 12px;
  }
}

.Alert-box {
  background: #ffefc2;
  border-radius: 10px;
  padding: 10px;
  max-width: 270px;
  width: 100%;
  font-size: 14px;
  line-height: normal;
  margin: 0 0 0 auto;
}
.heading-right {
  font-size: 14px;
  color: #fff;
  margin: 0;
  line-height: normal;
  display: flex;
  gap: 5px;
  align-items: center;
  justify-content: end;
  white-space: nowrap;
  // width: 100%;
}
.activecircle {
  background-color: #029d53;
  width: 10px;
  height: 10px;
  border-radius: 20px;
}
.inactive {
  background-color: #ff2e48;
  width: 10px;
  height: 10px;
  border-radius: 20px;
}
.ai-analysis {
  background-color: #ffc626;
  width: 10px;
  height: 10px;
  border-radius: 20px;
}

.time-icon {
  border: 1px solid #ffffff52;
  border-radius: 10px;
  padding: 8px;
  max-width: 35px;
  width: 100%;
  height: 35px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: none;
}
.page-title-actions p {
  color: #fff;
  font-size: 14px;
  white-space: nowrap;
}
.new-account-modal {
  .modal-header {
    background: #253944;
    .modal-title {
      color: #fff;
    }
  }
  .btn-close {
    filter: brightness(1) invert(1);
    border: 2px solid;
    border-radius: 50px;
    font-size: 8px;
    padding: 5px;
    opacity: 1 !important;
    margin-right: 5px;
  }
  .modal-body {
    h1 {
      text-align: center;
      color: #000;
      .rate {
        font-size: 35px;
        font-weight: 600;
      }
      .monthly {
        font-size: 16px;
        font-weight: 600;
      }
    }
  }
  .activePlan {
    background: #1c8e50;
    color: #fff;
  }
  .yellowText {
    background: #ffc626;
    color: #000;
    border-radius: 15px;
    position: absolute;
    top: -9px;
    right: -37px;
    padding: 0px 10px;
    font-size: 12px;
  }


  .plans-list {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    column-gap: 30px;
    margin: 0;
    list-style: none;

  li {
    position: relative;
    padding-bottom: 10px;
    &::before {
      content: "";
      position: absolute;
      width: 20px;
      height: 20px;
      background-repeat: no-repeat;
      background-position-y: bottom;
      left: -25px;
      background-size: contain;
      top: 3px;
      background-image: url(../../assets/Img/done.svg);
   
    }
  }
  }
  .footer-title {
    font-size: 12px;
    color: rgb(126 126 126);
  }
  .modal-footer {
    border: none;
    flex-direction: column;
    padding-top: 0;
  }
}

.time-icon img {
  width: 15px;
  height: 15px;
  transition: transform 0.2s;
}

// .time-icon img.large {
//   width: 15px; /* Ensure width remains the same */
//   height: 15px; /* Ensure height remains the same */
// }
