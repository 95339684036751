.account-details {
  .accordion {
    max-width: 700px;
    margin: 0 auto;
    padding: 20px;
    @media screen and (max-width: 767px) {
      padding: 10px 20px;
    }
  }
  .accordion-button:not(.collapsed) {
    background: #01904b;
    color: #fff;
    font-weight: 600;
    z-index: 0;
  }
  .accordion-button:not(.collapsed)::after {
    background-image: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23ffffff'><path fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/></svg>") !important;
  }
  .accordion-body {
  table {
    thead {
      th {
        text-align: center;
        &:first-child {
          text-align: left;
        }
      }
    }
   tbody {
    td {
      text-align: center;
     &:first-child {
      text-align: left;
     }
    }
    th {
      text-align: left;
    }
   }
  }
  }
}

.table-sub-container .accordion-button {
  font-weight: 500;
  z-index: 0;
}
.account-details .accordion-item .form-label {
  margin-bottom: 5px;
  line-height: normal;
  font-size: 15px;
  font-weight: 500;
}

.password-changes {
  position: relative;
  button {
    position: absolute;
    top: 0;
    right: 0;
  }
}
.authentication-body {
  h5 {
    font-weight: 700;
    font-size: 20px;
    margin-bottom: 20px;
  }
}
.enable-button {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 30px;

  input[type='checkbox'] {
    height: 0;
    width: 0;
    visibility: hidden;
  }

  label {
    cursor: pointer;
    text-indent: -9999px;
    width: 44px;
    height: 23px;
    background: #bcbfceba;
    display: block;
    border-radius: 100px;
    position: relative;
  }

  label:after {
    content: '';
    position: absolute;
    top: 1px;
    left: 0;
    width: 22px;
    height: 22px;
    background: #aeb0bc;
    border-radius: 90px;
    transition: 0.3s;
  }

  input:checked + label {
    background: #20a466;
    border: 1px solid #ccc;
  }

  input:checked + label:after {
    left: calc(100% - -1px);
    background: #fff;
    transform: translateX(-100%);
    top: 0px;
  }

}



