.header-main {
  background: #283947;
  position: fixed;
  width: calc(100% - 326px);
  z-index: 20;
  min-height: 60px;

  @media screen and (max-width: 992px) {
    width: 100%;
  }

  .brand-logo {
    max-width: 180px;
    cursor: pointer;
    @media screen and (max-width: 1150px) {
      max-width: 155px;
      width: 100%;
    }
  }

  .navbar-collapse {
    @media screen and (max-width: 992px) {
      padding: 20px;
    }
  }

  .navbar {
    padding-left: 30px;
    padding-right: 30px;
    padding-top: 10px;
    padding-bottom: 10px;
    border-bottom: 1px solid #8080805e;
    @media screen and (max-width: 1150px) {
      padding: 10px 20px;
    }
  }

  .navbar-toggler {
    @media screen and (max-width: 992px) {
      filter: invert(1);
    }
  }

  .navbar-nav {
    .dropdown-toggle::after {
      content: none;
    }
    .toggle-down-arrow {
      transform: rotate(-90deg);
      transform-origin: center center;
      transition: all 0.2s ease;
      margin: 0 0 0 4px;
    }
    .dropdown-toggle.show {
      .toggle-down-arrow {
        transform: rotate(0);
        transform-origin: center center;
      }
    }
    .nav-link {
      color: #fff;
      font-size: 16px;
      font-weight: 400;
      padding: 8px 25px;
      @media screen and (max-width: 1150px) {
        padding: 8px 13px;
      }
      @media screen and (max-width: 992px) {
        padding: 8px 0;
      }
    }
  }

  .headerright {
    align-items: center;
    @media screen and (max-width: 1150px) {
      align-items: start;
    }
    @media screen and (max-width: 992px) {
      padding: 8px 0;
    }
    svg {
      max-width: 13px;
    }
    .dropdown-menu {
      left: -175px;
      padding: 0;
      border-radius: 8px;
    }
    .nav-link {
      padding: 0;
    }
  }
}

.page-title {
  padding: 15px 30px 15px 30px;
  background: #283947;
  position: fixed;
  width: calc(100% - 326px);
  margin-top: 60px;
  z-index: 0;
  min-height: 60px;

  @media screen and (max-width: 992px) {
    width: 100%;
  }
  @media screen and (max-width: 767px) {
    padding: 20px 20px 20px 20px;
    margin-top: 60px;
    position: static;
  }

  .page-title-wrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;

    @media screen and (max-width: 992px) {
      flex-wrap: wrap;
      gap: 10px;
    }

    .page-title-heading {
      color: #fff;

      .title-subheading {
        max-width: 610px;
        margin-top: 10px;
        // margin-bottom: 10px;
        font-size: 14px;
        font-weight: 400;
        color: #bbbdc2;
      }

      .btn:hover {
        background-color: transparent !important;
        border-color: transparent !important;
      }
    }

    .page-title-actions {
      max-width: 260px;
      width: 100%;
      @media screen and (max-width: 767px) {
        max-width: 100%;
      }
      .action-list {
        .AccountActive {
          width: 9px;
          height: 9px;
          background: #1abc00;
          border-radius: 50px;
          display: inline-block;
          margin-right: 10px;
        }

        li {
          font-size: 16px;
          font-weight: 400;
          color: #fff;

          &:first-child {
            margin-bottom: 10px;
          }

          span {
            color: #708291;
          }
        }
      }
    }
  }
}

.profile__text {
  background: #f3f3f3;
  display: flex;
  align-items: center;
  gap: 15px;
  padding: 10px;
  border-radius: 8px 8px 0 0;
}

.profileText {
  width: 30px;
  height: 30px;
  background: #42effc;
  padding: 10px;
  border-radius: 30px;
  display: flex;
  align-items: center;
  justify-content: center;

  span {
    color: white;
  }
}

.profile-billing {
  padding: 0;
  margin: 15px 0 0 0;
  list-style: none;

  .dropdown-item.active,
  .dropdown-item:active {
    background: #f8f9fa;
  }
}

.profile--text {
  h6 {
    color: #000;
    font-weight: 600;
    font-size: 16px;
  }

  p {
    font-size: 14px;
  }
}
.profile--div {
  display: flex;
  align-items: center;
  cursor: pointer;
}
.profile--icons img {
  max-width: 17px;
}
.custom-brand {
  width: 221px;
  @media screen and (max-width: 357px) {
    width: 150px;
  }
}
.page-title-wrapper .btn-outline.disabled,
.btn-outline:disabled {
  border: 1px solid #fff;
  border-radius: 10px;
  color: #fff;
  background: none;
}
