.policy-heading {
  font-size: 25px;
  font-weight: 600;
  color: #000;
  margin-bottom: 25px;
}
.policy-subtitle {
  font-size: 20px;
  font-weight: 600;
  color: #000;
 
  margin-bottom: 15px;
}
.policy-list {
  margin-bottom: 25px;
}
.policy-section p {
  margin-bottom: 25px;
  a {
    color: #f95a5a;
    text-decoration: none;
    &:hover {
      text-decoration: underline;
    }
  }
}
.policy-section p,
.policy-list li {
  text-align: justify;
  font-size: 16px;
  font-weight: 400;
  color: #000;
}
.policy-section {
  padding: 85px 0 50px 0;
  // max-width: 900px;
  // margin: 0 auto;
  @media screen and (max-width:992px) {
    padding: 85px 20px 50px;
  }
}
li{
  text-align: justify;
}

.policy-section {
  padding: 85px 0 50px;
  overflow-y: scroll;
  max-height: 100vh;
}