.Plan--details {
    min-height: 200px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 20px 0;
p {
    color: #c3c3c3;
}
}
.login-form.subscriptionForm  {
     h3 {
        font-size: 25px;
        margin-bottom: 5px;
     }
    .switches-container {
        position: relative;
        background: #fff;
        line-height: 35px;
        border-radius: 3rem;
        border: 1px solid #1c8e50;
        margin-top: 20px;
    }
    
    .switches-container input {
        visibility: hidden;
        position: absolute;
        top: 0;
    }
    .switches-container label {
        width: 50%;
        text-align: center;
        cursor: pointer;
        color: #1c8e50;
        font-weight: 500;
    }
    .switch-wrapper {
        position: absolute;
        top: 0;
        bottom: 0;
        width: 50%;
        z-index: 3;
        transition: transform .5s cubic-bezier(.77, 0, .175, 1);
    }
    
    .switch {
        border-radius: 3rem;
        background: #1c8e50;
        height: 100%;
    }
    .switch div {
        width: 100%;
        text-align: center;
        opacity: 0;
        display: block;
        color: #fff;
        transition: opacity .2s cubic-bezier(.77, 0, .175, 1) .125s;
        will-change: opacity;
        position: absolute;
        top: 0;
        left: 0;
    }
    
    .switches-container input:nth-of-type(1):checked~.switch-wrapper {
        transform: translateX(0%);
    }
    
    .switches-container input:nth-of-type(2):checked~.switch-wrapper {
        transform: translateX(100%);
    }
    
    .switches-container input:nth-of-type(1):checked~.switch-wrapper .switch div:nth-of-type(1) {
        opacity: 1;
    }
    
    .switches-container input:nth-of-type(2):checked~.switch-wrapper .switch div:nth-of-type(2) {
        opacity: 1;
    }
  
}