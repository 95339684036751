@font-face {
    font-family: 'Karla';
    src: url('Karla-Regular.eot');
    src: url('Karla-Regular.eot?#iefix') format('embedded-opentype'),
        url('Karla-Regular.woff') format('woff'),
        url('Karla-Regular.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Karla';
    src: url('Karla-Bold.eot');
    src: url('Karla-Bold.eot?#iefix') format('embedded-opentype'),
        url('Karla-Bold.woff') format('woff'),
        url('Karla-Bold.ttf') format('truetype');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Karla';
    src: url('Karla-SemiBold.eot');
    src: url('Karla-SemiBold.eot?#iefix') format('embedded-opentype'),
        url('Karla-SemiBold.woff') format('woff'),
        url('Karla-SemiBold.ttf') format('truetype');
    font-weight: 600;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Karla';
    src: url('Karla-Light.eot');
    src: url('Karla-Light.eot?#iefix') format('embedded-opentype'),
        url('Karla-Light.woff') format('woff'),
        url('Karla-Light.ttf') format('truetype');
    font-weight: 300;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Karla';
    src: url('Karla-Medium.eot');
    src: url('Karla-Medium.eot?#iefix') format('embedded-opentype'),
        url('Karla-Medium.woff') format('woff'),
        url('Karla-Medium.ttf') format('truetype');
    font-weight: 500;
    font-style: normal;
    font-display: swap;
}

