.rbc-toolbar button:active,
.rbc-toolbar button.rbc-active,
.rbc-toolbar button:focus,
.rbc-toolbar button:hover {
  background-color: #5c9c8b;
  border-color: #5c9c8b;
  color: #fff;
}

.rbc-toolbar button:active,
.rbc-toolbar button.rbc-active:hover,
.rbc-toolbar button.rbc-active:focus {
  background-color: #5c9c8b;
  border-color: #5c9c8b;
  color: #fff;
}

.rbc-toolbar button:active,
.rbc-toolbar button.rbc-active {
  background-color: #5c9c8b;
  border-color: #5c9c8b;
  color: #fff;
}

.rbc-toolbar button {
  background-color: #73c3ae;
  color: #fff;
  border: 1px solid #73c3ae;
}

.rbc-toolbar button:active:hover,
.rbc-toolbar button:active:focus,
.rbc-toolbar button.rbc-active:hover {
  background-color: #73c3ae;
  color: #fff;
  border: 1px solid #73c3ae;

}

.rbc-header {
  color: gray;
  font-weight: 400;
}

.rbc-off-range-bg {
  background: #f6f6f6;
}

.rbc-row-segment {
  min-height: 93px;
  padding: 3px;

  @media screen and (max-width:767px) {
    min-height: 42px;
  }
}

.rbc-toolbar {
  padding: 10px 0;
  margin: 0;
    @media screen and (max-width:767px) {
      gap: 7px;
      padding: 16px 0;
    }

  .rbc-btn-group {
    @media screen and (max-width:767px) {
      width: 100%;
      text-align: center;
    }
  }

  .rbc-toolbar-label {
    @media screen and (max-width:767px) {
      width: 100%;
      text-align: center;
    }
  }
}

.rbc-calendar {
  padding: 0 20px 20px 20px;
  height: 100% !important;
}

.positive-data,
.negative-data {
  height: 100%;
  background: #d5f3e4;
  border: 2px solid #168956;
  color: #168956;
  border-radius: 5px;
  padding: 7px;
  text-align: center;
  font-weight: 500;
  font-size: 15px;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  p {
    font-size: 12px;
    margin: 0;
    color: #168956;

    @media screen and (max-width:1082px) {
      font-size: 10px;
    }

    @media screen and (max-width:767px) {
      display: none;
    }
  }
}

.negative-data {
  background: #f9bebd;
  border: 2px solid #f28889;
  color: #000;

  p {
    color: #000;
  }
}


.journal-calendar .rbc-date-cell {
  display: none;
}

.date {
  font-size: 12px;
  font-weight: 600;
  position: absolute;
  right: 4px;
  top: 0;
  color: #929292;

  @media screen and (max-width:767px) {
    display: flex;
    align-items: center;
    justify-content: center;
    right: 0;
    width: 100%;
    height: 100%;
  }
}

.rbc-date-cell {
  display: none;
}

.rbc-month-row {
  min-height: 94px;

  @media screen and (max-width:767px) {
    min-height: 42px;
  }
}
.rbc-label.rbc-time-header-gutter , .rbc-time-gutter.rbc-time-column {
 display: none;
}

.rbc-time-slot {
  border-top: none !important;

}
.rbc-row-bg {
  background: #f7f7f7 ;
}
.rbc-day-slot.rbc-time-column{
  display: none;
}
.rbc-time-content {
 border-top: none;
}
.rbc-now.rbc-today {
.rbc-timeslot-group {
  .rbc-time-slot {
    background: #eaf6ff;
  }
}
}
.rbc-button-link span {
@media screen and (max-width:767px) {
  font-size: 12px;
}
}

.event-content {
  z-index: 1; /* Ensure the content is above the date label */
}

.date-label {
  position: absolute;
  top: 0;
  right: 0;
  // background-color: white; /* Adjust background color as needed */
  padding: 4px;
  border-radius: 4px;
  font-size: 12px;
}
.red {
  color: red;
}
.cross-icon-container {
  display: inline-block;
  padding: 7px; /* Adjust the padding to increase the size of the circle */
  border-radius: 50%;
  cursor: pointer;
}

.cross-icon-container:hover {
  background-color: #efefef; /* Replace with the desired background color */
}

.cross-icon {
  display: block; /* Ensures the image is centered within the container */
  width: 12px;
}

.no-loosing-trade {
  font-size: 23px;
  font-weight: 700;
  line-height: normal;
  color: #448b56;
  margin-top: 4px;
  letter-spacing: -1px;
}
.infinit-icon {
  width: 35px;
  height: 35px;
  fill: #448b56;
}

.no-loosing-trades {
  font-size: 13px;
  font-weight: 700;
  line-height: normal;
  color: #448b56;
  margin-top: 4px;
  letter-spacing: -1px;
}
.widget-subheading  .infinity-icon {
  width: 35px;
  fill: #448b56;
  height: 25px;
  margin-left: -5px;
}

.no-losing-trades {
  color: #448b56;
  font-size: 13px;
  font-weight: 600;
  letter-spacing: -1px;
}
