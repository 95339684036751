.otp-input-fields {
    margin: auto;
    max-width: 400px;
    width: auto;
    display: flex;
    justify-content: center;
    gap: 10px;
  
    input {
      height: 40px;
      width: 40px;
      background-color: transparent;
      border-radius: 4px;
      border: 1px solid #d9d9d9;
      text-align: center;
      outline: none;
      font-size: 16px;
      &::-webkit-outer-spin-button,
      &::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
      }
  
      /* Firefox */
      &[type='number'] {
        -moz-appearance: textfield;
      }
      &:focus {
        border-width: 2px;
        border-color: darken(#2f8f1f, 5%);
        font-size: 20px;
      }
    }
  }