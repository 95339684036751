@import './fonts.scss';
@import './variable.scss';

// Variable
:root {
  --dark-text: #253944;
  --light-text: #6b6b6b;
}

body {
  font-family: 'Karla', system-ui, -apple-system, 'Segoe UI', Roboto,
    'Helvetica Neue', 'Noto Sans', 'Liberation Sans', Arial, sans-serif,
    'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji';
  background: #f8f9fa;
  overflow: hidden;
}

// Variable
h1 {
  font-size: 30px;
  font-weight: 600;
  line-height: normal;
  color: var(--dark-text);
}

h3 {
  font-size: 20px;
  font-weight: 600;
}

h6 {
  font-size: 18px;
  font-weight: 500;
  color: var(--dark-text);
}

p {
  font-size: 16px;
  font-weight: 500;
  color: var(--dark-text);
}
h1,
h2,
h3,
h4,
h5,
h6,
p {
  margin: 0;
  padding: 0;
}

a {
  color: #030dcf;
}

.btn {
  font-size: 16px;

  &.btn-outline {
    border: 1px solid #fff;
    border-radius: 10px;
    color: #fff;
  }
}

.btn {
  font-weight: 500;
  font-size: 14px;
  line-height: normal;
  border-radius: 9px;
  padding: 6px 30px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
}
.btn.disabled,
.btn:disabled {
  pointer-events: none;
  color: #7a69d6;
  background-color: #e0e2ea;
  border-color: #e0e2ea;
}
.btn-outline {
  color: #fff;
  border: 1px solid #f0f2f4;
  &:hover,
  &:focus,
  &:active {
    border: 1px solid #fff;
    color: #fff !important;
  }
}
.btn-outline-dark {
  color: #000;
  border: 1px solid #000;
  background: #fff;

  &:hover,
  &:focus,
  &:active {
    border: 1px solid #253944;
    color: #fff !important;
    background: #253944 !important;
  }
}
.btn-xs {
  min-height: 32px;
}
.btn-sm {
  min-height: 40px;
}
.btn-md {
  min-width: 90px;
}
.btn-lg {
  font-size: 16px;
  min-height: 50px;
}
.btn-xl {
  font-size: 16px;
  min-height: 55px;
}
.btn-xll {
  min-width: 215px;
}
.btn-primary {
  background: #1c8e50;
  color: #fff;
  border-color: #1c8e50;
  &:hover,
  &:focus,
  &:active {
    color: #fff;
    background-color: #1c8e50;
    border-color: #1c8e50;
  }
}
.btn-secondary {
  background: #253944;
  border-color: #253944;
}
.loader {
  background: url(../Img/fractal-loader.gif) no-repeat;
  width: 50px;
  height: 50px;
  margin: 0 auto;
  background-size: contain;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  z-index: 5;
}
.loader2 {
  background: url(../Img/fractal-loader.gif) no-repeat;
  width: 50px;
  height: 50px;
  margin: 0 auto;
  background-size: contain;
}
.error-404 {
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  padding: 95px 0;
}
.error-404 .small-title {
  color: #e21f32;
  font-weight: 500;
}
.error-404 .heading {
  font-size: 130px;
  font-weight: 700;
  line-height: normal;
  margin: 15px 0;
  color: #161616;
}
.error-404 .subtitle {
  max-width: 450px;
  margin: 0 auto;
  margin-bottom: 30px;
}
.error-404 .subtitle p {
  font-size: 16px;
  line-height: normal;
  color: #3d4b56;
  font-weight: 500;
}
.error-btn .btn {
  background: #1c8e50;
  border: 1px solid #1c8e50;
  font-size: 14px;
  color: #fff;
}

.progress-border :where(.css-dev-only-do-not-override-d2lrxs).ant-progress-line {
  line-height: 3px;
}
.mobile-view-chatboat {
  .offcanvas-header {
    display: none;
  }
}
.offcanvasWidth {
  max-width: 85%;
}
.header-full-width {
  .header-main {
    width: 100%;
  }
}