

.analysis-block {
  padding: 25px;
}

.analysis-header {
  text-align: center;
  max-width: 450px;
  margin: 10px auto 40px;
}

.body-paragraph {
  margin: 10px 0;
}

.step-circle {
  width: 48px;
  height: 48px;
  border-radius: 50px;
  align-items: center;
  justify-content: center;
  display: flex;
  margin: 6px auto 15px;
  background: #8e8e8e;
  position: relative;
}

.step-circle::after {
  content: '';
  position: absolute;
  border: 1px solid #d4d4d4;
  left: 66px;
  width: 220px;
}
@media screen and (max-width: 992px) {
  .step-circle::after {
    width: 155px;
  }
}
@media screen and (max-width: 767px) {
  .step-circle::after {
    border: 0;
  }
}

.last-step:after {
  border: none;
}

.stepcount {
  background: #448b56;
}

.step-no {
  color: #fff;
  font-size: 18px;
  font-weight: 600;
}

.analysis-step-block {
  display: flex;
  justify-content: center;
  gap: 60px;
}
@media screen and (max-width: 767px) {
  .analysis-step-block {
    flex-wrap: wrap;
    gap: 40px;
  }
}

.analysis-steps {
  width: 25%;
  text-align: center;
}
@media screen and (max-width: 767px) {
  .analysis-steps {
    width: 100%;
  }
}
.step-title {
  font-size: 14px;
}

.analysis-footer {
  max-width: 425px;
  margin: 0 auto;
  padding: 10px 0 0 0;
  text-align: center;
}
.unsuccessfull-cross img {
  max-width: 53px;
  height: 53px;
  margin: 0 auto 20px;
}

.fa-app-platform img {
  max-width: 144px;
  width: 100%;
  border-radius: 8px;
  margin: 18px 0;
}
.analysis-footer p {
  font-weight: 500;
  font-size: 16px;
  line-height: normal;
}

.analysis-footer h6 {
  font-weight: 600;
  color: var(--dark-text);
}
.account-container {
  padding: 6px 10px;
    border-bottom: 1px solid #e0e0e0;
    .form-select {
      max-width: 220px;
      @media screen and (max-width:767px) {
      max-width: 100%;
      }
    }
}

.step-page-section {
  .header-main {
    width: 100%;
  }
}