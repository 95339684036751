.new-account-modal .modal-body  .initiateModalBody {
  max-width: 350px;
  margin: 0 auto;
  padding: 20px 0;

}
.initiateModalBody {
h1 {
  color: #1c8e50;
  font-size: 25px;
  font-weight: 600;
 margin-bottom: 10px;
}
p {
  color: #000;
  text-align: center;
  margin-bottom: 15px;
  margin-top: 10px;
}
}
.initiateModalLoader {
  position: relative;
  width: 50px;
  height: 50px;
  margin: 15px auto;
}
.initiateModalContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  max-width: 500px;
  width: 100%;
  margin: 0 auto;
  text-align: center;
  height: 80vh;
}

.dataerror{
  padding-top: 5px;
  color: red;
  font-size: 16px;
  justify-content: center;
  align-items: center;
}

.analysis-page-section {
  .header-main {
    width: 100%;
  }
  .left_container {
    width: 100%;
  }
}