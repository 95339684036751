// ...colors
$White: #fff;
$gray: #dfdfdf;

.content-wrapper {
  padding: 20px 30px 20px 30px;
  background: #f8f9fa;
  height: calc(100vh - 120px);
  overflow-y: auto;
  margin-top: 120px;
  position: relative;
  z-index: 0;

  @media screen and (max-width: 767px) {
    padding: 15px 15px 80px;
    margin-top: 0;
  }

  @media screen and (max-width: 640px) {
    height: calc(100vh - 225px);
  }

  @media screen and (max-width: 450px) {
    height: calc(100vh - 245px);
  }

  // @media screen and (max-width: 450px) {
  //   height: calc(100vh - 245px);
  // }
  .card.widget-content {
    padding: 15px;
    background: $White;
    border-radius: 5px;
    border: 0.5px solid $gray;
    height: 100%;

    @media screen and (max-width: 1350px) {
      padding: 11px;
    }

    @media screen and (max-width: 992px) {
      margin-bottom: 10px;
    }
  }

  .widget-heading {
    font-size: 16px;
    font-weight: 400;
    color: #3d4b56;
    line-height: normal;
  }

  .widget-subheading {
    font-weight: 600;
    font-size: 20px;
    color: #091630;

    @media screen and (max-width: 1350px) {
      font-size: 18px;
    }
  }
}

.days-component {
  position: relative;

  .nav-pills {
    border-radius: 5px;
    border: 0.5px solid $gray;
    background: $White;

    .nav-item {
      border-right: 0.5px solid $gray;
      padding: 0;
      text-align: center;

      &:last-child {
        border: none;
      }

      &:first-child {
        .nav-link.active {
          border-radius: 5px 0px 0px 5px !important;
        }
      }
    }
  }

  .tab-content ul li p {
    font-size: 15px;
    font-weight: 400;

    &:last-child {
      text-align: end !important;
    }
  }

  .nav-link {
    color: #3d4b56 !important;
    font-weight: 500;
    padding: 10px;
    font-size: 15px;
    border-radius: 0;

    &.active {
      background: #daf6d4;
      border-radius: 0px;
      color: #448b56 !important;
    }
  }

  .tab-pane {
    border-radius: 5px;
    border: 0.5px solid $gray;
    background: $White;
    padding: 24px 15px;

    .daystitle {
      font-weight: 600;
      color: #3d4b56;
      position: relative;

      span {
        background: $White;
        z-index: 1;
        position: relative;
        padding-right: 7px;
      }

      &::after {
        content: '';
        position: absolute;
        width: 100%;
        border: 0.5px solid #98a8b563;
        top: 50%;
        transform: translateY(-50%);
        right: 0;
      }

      &.success {
        color: #148c00;
      }

      &.danger {
        color: #f00;
      }
    }
  }
}

.animated-progress {
  height: 22px;
  border-radius: 4px;
  position: relative;
  padding: 0;
  background: #bcbbbb;
}

.consistency .bg-danger {
  border-radius: 4px;
  background: linear-gradient(
    115deg,
    #f00 0%,
    #f9cc00 33.16%,
    #e9cc11 50.87%,
    #50c8b1 77.95%
  );
}

.summary-chart {
  border-radius: 5px;
  border: 0.5px solid $gray;
  background: #fff;
  padding: 25px 17px;
  margin-top: 16px;

  p {
    margin-bottom: 20px;
    font-weight: 600;
  }

  .profit {
    // min-height: 220px;

    & > div {
      border-bottom: 1px solid #4bd8ca;
      padding-bottom: 6px;
    }

    span {
      border-radius: 4px;
      background: #4bd8ca;
      padding: 5px 8px;
      color: $White;
    }
  }

  .loses {
    & > div {
      border-top: 1px solid #ff6b7e;
      padding-top: 6px;
    }

    span {
      border-radius: 4px;
      background: #ff6b7e;
      padding: 5px 8px;
      color: $White;
    }
  }
}

.buy {
  color: #20a466;
  background: rgb(179 241 212 / 23%) !important;
  padding: 5px 13px !important;
  border-radius: 4px;
  border: 1px solid #0fc46f73;
  display: inline-block;
  font-size: 15px;
  line-height: normal;
}

.sell {
  color: #ff0000;
  background: rgb(255 0 0 / 9%) !important;
  padding: 5px 13px !important;
  border-radius: 4px;
  border: 1px solid #ff0000;
  display: inline-block;
  line-height: normal;
  font-size: 15px;
}

.trades-block {
  margin-top: 20px;

  th {
    background: #cccccc30;
  }

  tr {
    th,
    td {
      white-space: nowrap;
      font-size: 14px;
    }

    .icon {
      max-width: 18px;
      margin: 0 auto;
      color: #ff0000;
    }
  }
}

.reflections-textarea {
  border: 1px solid #ccc;
  border-radius: 8px;
  width: 100%;
}

.step-footer p {
  line-height: normal;
  margin-top: 10px;
  font-size: 14px;
  color: #6b6b6b;
}

.step-footer h3 {
  font-size: 18px;
  color: #253944;
  font-weight: 600;
}

.check-emoje {
  display: flex;
  gap: 20px;
  align-items: center;
  margin-bottom: 15px;

  @media screen and (max-width: 767px) {
    gap: 16px;
  }
}

.emoje svg {
  max-width: 20px;
  height: 20px;
}

.emojeIcon {
  color: #ebce01;
}

.pagination-block {
  .ant-pagination {
    display: flex;
    align-items: center;
  }
}

.table--pagination {
  display: flex;
}

.main_page {
  display: flex;
}

.left_container {
  width: calc(100% - 326px);
  position: relative;

  @media screen and (max-width: 992px) {
    width: 100%;
  }
}

.right_container {
  max-width: 326px;
  width: 100%;
  border-left: 1px solid #ccc;
  background: #fff;
  overflow: auto;
  height: 100vh;
  position: relative;
  overflow: hidden;

  @media screen and (max-width: 992px) {
    display: none;
  }
}

.chat-preview {
  overflow-y: auto;
  overflow-x: hidden;
  scroll-behavior: smooth;
  scrollbar-width: thin;
}
.trades-chatboat {
  height: 100%;
}

.chat-preview-flex {
  display: flex;
  flex-direction: column-reverse;
}

.chat-preview-container {
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
  overflow-y: hidden;
  position: relative;
  justify-content: space-between;
}

.message-container {
  display: flex;
  flex-direction: column;
  gap: 12px;
  padding: 20px 12px 50px;
}

.outer-message-container {
  display: flex;
  align-items: end;
}

.last-item {
  order: 2;
}

.chat-icon-skeleton {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 0.3em;
  max-width: 25px;
  width: 100%;
  height: 25px;
  border-radius: 50px;
  background: #00914a;

  img {
    max-width: 17px;
  }

  .chat-icon-skeleton-text {
    color: #fff;
    font-weight: 600;
    font-size: 16px;
  }
}

.chat-msg-icon {
  width: 25px;
  height: 25px;
  color: #154d86;
}

.bot-message-container {
  width: fit-content;
  max-width: 80%;
  border: 1px solid #f8f9fa;
  border-top-left-radius: 0.7em;
  border-top-right-radius: 0.7em;
  border-bottom-right-radius: 0.7em;
  overflow-wrap: break-word;
  background-color: #f8f9fa;
  padding: 10px;
}

.bot-message {
  align-self: flex-start;
  font-size: 14px;
  color: #202428;
  padding-bottom: 5px;
}

.outer-user-message-container {
  padding: 0.3em;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  flex: 1 0 auto;
  align-items: flex-end;
}

.alert-message-container {
  display: flex;
  flex-direction: column;
  align-self: flex-end;
  margin-right: 0.3em;
  width: 85%;
  background-color: #ffefc2;
  border: 1px solid #ffefc2;
  border-top-left-radius: 0.7em;
  border-top-right-radius: 0.7em;
  border-bottom-left-radius: 0.7em;
  overflow-wrap: break-word;
  align-self: flex-end;
  padding: 0.7em;
}

.user-message-container {
  display: flex;
  flex-direction: column;
  align-self: flex-end;
  margin-right: 0.3em;
  width: 85%;
  background-color: #f8f9fa;
  border: 1px solid #f8f9fa;
  border-top-left-radius: 0.7em;
  border-top-right-radius: 0.7em;
  border-bottom-left-radius: 0.7em;
  overflow-wrap: break-word;
  align-self: flex-end;
  padding: 0.7em;
}

.user-message {
  position: relative;
  float: right;
  font-size: 14px;
  max-width: 85%;
  color: #000;
  padding-bottom: 5px;
}

.time-div {
  color: #766f6f;
  font-size: 12px;
}

.chat-footer {
  border-top: 1px solid #d1cfbb;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: #fff;
  padding: 12px;
  position: absolute;
  bottom: 0;
  width: 100%;

  img {
    // position: absolute;
    // right: 15px;
    // top: 14px;
  }
}

.chat-block.chat-block-height {
  height: calc(100vh - 50px);
}

.chat-block {
  display: flex;
  flex-direction: column;
  height: calc(100vh - 135px);
  // height: 100vh;
  // padding-bottom: 120px;
}

.chat-input {
  height: 45px;
  width: calc(84% - 45px);
  border: none;
  font-family: 'Mulish', sans-serif;
  padding: 0 2em 0 0.1em;
  font-size: 16px;

  &:focus {
    outline: 0;
  }
}

.chat-input::-webkit-scrollbar {
  display: none;
}

.chat-modal {
  max-width: 200;
}

.chat--icon {
  position: fixed;
  background: #448b56;
  border-radius: 50px;
  width: 45px;
  height: 45px;
  display: flex;
  align-items: center;
  justify-content: center;
  bottom: 15px;
  right: 20px;
  border: 0;

  img {
    max-width: 24px;
  }

  .rcb-chat-window {
    width: 350px !important;
    height: 550px !important;
    inset: unset !important;
    bottom: 20px !important;
    right: 20px !important;
  }

  .rcb-chat-footer-container {
    padding: 0 !important;
    border-top: none !important;
  }

  @media screen and (min-width: 992px) {
    display: none;
  }
}

.chatboat-section {
  display: flex;
  flex-direction: column;
}

.chatboat-chat-section {
  display: flex;
  flex-direction: column;
  height: calc(100vh - 61px);
  position: relative;
  justify-content: space-between;
}

.chat-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: #283947;
  padding: 10px;
  position: relative;
  z-index: 1;
  height: 61px;

  p {
    color: #fff;
    font-size: 16px;
  }

  .chat-edit {
    color: #fff;
    border: 1.5px solid #6f6f6f;
    padding: 6px 12px;
    border-radius: 5px;

    &:active {
      border: 1.5px solid #6f6f6f;
      color: #fff;
    }
  }
}

.tranding-plan {
  padding: 10px 15px;
  border-bottom: 1px solid #ccc;
  position: sticky;
  top: 0;
  background: #fff;
  z-index: 1;
  line-height: 12px;

  label {
    white-space: nowrap;
    font-size: 10px;
  }

  p {
    font-size: 12px;
    line-height: 14px;
  }
}

.cross-icon {
  max-width: 12px;
}

// progress

.yellow-dote {
  max-width: 9px;
  width: 100%;
  height: 9px;
  background: #ffc626;
  border-radius: 30px;
}

.green-dote {
  max-width: 9px;
  width: 100%;
  height: 9px;
  background: #448b56;
  border-radius: 30px;
}

.red-dote {
  max-width: 9px;
  width: 100%;
  height: 9px;
  background: #ff2e48;
  border-radius: 30px;
}

.progress-border {
  max-width: 147px;
  height: 7px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
}

.yellow-progress {
  width: 100%;

  .progress-border {
    // background: #80e4da;
    // border-color: #80e4da;
    // border-radius: 0;
    // display: inline-block;

    .progress {
      background: #00c8b5;
      border-radius: 0;
    }
  }
}

.ant-progress-inner {
  max-height: 6px;
}

.red-progress {
  width: 100%;

  .progress-border {
    // background: #f47788;
    // border-color: #ff2e48;
    // border-radius: 0;
    // display: inline-block;

    .progress {
      background: #ff2e48;
      border-radius: 0;
    }
  }
}

.progress-container canvas {
  margin: 0 0 25px 0;
}

.progress-container {
  p {
    font-size: 14px !important;
  }

  label {
    font-size: 11px;
  }
}

.graph-container {
  height: 285px;

  canvas {
    height: 100% !important;
  }
}

.journal-entry {
  display: flex;
  gap: 60px;
  margin-bottom: 20px;
  margin-top: 5px;

  @media screen and (max-width: 767px) {
    flex-wrap: wrap;
    gap: 25px;
  }

  p {
    font-size: 14px;
    color: #525252 !important;
    margin-bottom: 3px;
    line-height: normal;
  }

  h6 {
    font-size: 18px;
    color: #091630;
    font-weight: 600;
  }
}

.fill-available {
  width: -webkit-fill-available;
}

.threshold-container {
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;
}

.threshold-box {
  width: 100px;
  padding: 2px;
  border-radius: 5px;
  text-align: center;
  font-weight: bold;
}

.profit-box {
  background-color: #73c7e6; /* Sky blue */
  color: white;
  font-size: 12px;
}

.loss-box {
  background-color: rgb(213, 76, 76);
  color: white;
  font-size: 12px;
}

.loading-text {
  text-align: center;
  color: gray;
  font-size: 12px;
}

.no-loosing-trades {
  font-size: 13px;
  font-weight: 700;
  line-height: normal;
  color: #448b56;
  margin-top: 4px;
  letter-spacing: -1px;
}
.infinity-icon {
  width: 35px;
  height: 35px;
  fill: #448b56;
}
.profit-target-reached {
  background: rgb(255 173 15 / 25%);
  padding: 7px 9px;
  font-size: 14px;
  font-weight: 700;
  border-radius: 13px;
  max-width: 94px;
  width: 100%;
  text-align: center;
  letter-spacing: -0.7px;
  line-height: normal;
  position: absolute;
  top: -45px;
  left: 235px;
  @media screen and (max-width: 400px) {
    left: auto;
    right: 0;
  }
}
.trades-details-modal p.reflection-error {
  color: #FF0000;
}
