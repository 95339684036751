.left-trade {
  max-width: 200px;
  width: 100%;
  @media screen and (max-width: 767px) {
    max-width: 100%;
  }
}
.trade-flex {
  display: flex;
  @media screen and (max-width: 767px) {
    flex-wrap: wrap;
  }
}
.center-trade {
  width: 100%;
  padding: 0 15px;
  @media screen and (max-width: 767px) {
    margin-top: 25px;
  }
}
.right-trade {
  max-width: 120px;
  width: 100%;
  padding: 0 10px;
}
.tradelog-list p b {
  font-weight: 600;
}

.reflection--text {
  max-height: 100px;
  overflow-y: auto;
  font-weight: 400;
  line-height: 20px;
  color: #000;
}
.trades-details-modal {
  ::-webkit-scrollbar {
    width: 4px;
  }

  ::-webkit-scrollbar-track {
    background-color: #ebebeb;
    -webkit-border-radius: 5px;
    border-radius: 5px;
  }

  ::-webkit-scrollbar-thumb {
    -webkit-border-radius: 5px;
    border-radius: 5px;
    background: #00914a;
  }
}

.modal-content {
  border-radius: 15px;
}
.edit_icon {
  background-color: transparent !important;
}

.blank__table__card {
  background: #ffffff;
  box-shadow: 5px 5px 20px #e5e5e5;
  border-radius: 6px;
  text-align: center;
  padding: 20px;
  min-height: 100px;
  .blank__table__card__inner {
    font-weight: 500;
    font-size: 16px;
    line-height: 19px;
    color: #979797;
  }
}
.icon {
  cursor: pointer;
}

