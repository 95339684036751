.login-wrapper { 
  background-color: #fff;
  min-height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  .loginBlock {
    text-align: center;
    height: auto;
    padding: 25px 10px;
    box-sizing: border-box;
    max-width: 100%;
    margin: 0 auto;
    width: 100%;

    .brandLogo {
      max-width: 200px;
      width: 100%;
      margin: 0 auto 15px ;
      img {
        max-width: 100%;
        width: 100%;
      }
    }
  }
  .login-container {
  
    width: 100%;
  }
  .link_color {
    color: #7a69d6;
  }

  .login-form {
    width: 100%;
    max-width: 375px;
    margin: 0 auto;

    .sign-title {
      font-size: 20px;
      font-weight: 600;
      color: #000;
      margin-bottom: 15px;
    }
    p {
      font-weight: 400;
      color: #717374;
      font-size: 15px;
      display: inline-block;
    }
  }
}

.blur {
  filter: blur(1px); /* Adjust the blur radius as needed */
}
.password-toggle-btn {
  img {
    max-width: 16px;
  }
}

.rbc-allday-cell .rbc-row-content .rbc-row:last-child {
  display: none;
}
