.my-checkbox {
  display: inline-flex;
  align-items: center;
  vertical-align: text-top;
}

.my-checkbox__input {
  position: relative;
  flex-shrink: 0;
  width: 15px;
  height: 15px;
  appearance: none;
  border: none;
  cursor: pointer;
  border-radius: 0;
  background-color: transparent;
}

.my-checkbox__input:checked:before {
  background: #6155a1;
}

.my-checkbox__input:before {
  content: '';
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  border: 0.0625rem solid #000;
  background-color: transparent;
  transition: background-color 0.2s;
  border-radius: 2px;
}

.my-checkbox__input:checked:after {
  width: 8px;
  height: 4px;
  transition: height 0.08s ease-out, width 0.08s ease-out 0.1s;
  border-color: #fff;
  top: -4.5px;
  left: -2px;
}

.my-checkbox__input:after {
  content: '';
  position: absolute;
  width: 0;
  height: 0;
  transform: rotate(-45deg);
  transform-origin: 1.125rem -0.0625rem;
  border-bottom: 0.125rem solid transparent;
  border-left: 0.125rem solid transparent;
}

.my-checkbox__label {
  margin-left: 0.5rem;
}
.timeZone {
  text-align: left;
}
.cursor-pointer {
  cursor: pointer;
}