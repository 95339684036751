.table-container {
  padding: 15px;
  overflow-y: auto;
  // height: calc(100vh - 60px);
  height: 90vh;
  margin-top: 60px;
  @media screen and (max-width: 767px) {
    padding: 15px 15px 80px 15px;
  }
}

.table-sub-container {
  box-shadow: rgba(17, 17, 26, 0.1) 0px 0px 16px;
  border-radius: 8px;
  background: #fff;
}

.main-heading {
  padding: 15px;
  border-bottom: 1px solid #ccc;
}

.fa-filter-block {
  padding: 10px 20px;
  border-bottom: 1px solid #ccc;
  @media screen and (max-width: 992px) {
    padding: 10px 15px;
  }
}
.account-feild {
  @media screen and (max-width: 767px) {
    width: 100%;
  }
}

.fa-filter {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 10px;
  @media screen and (max-width: 767px) {
    flex-wrap: wrap;
  }
}

.table-wrapper {
  padding: 0 40px;
  @media screen and (max-width: 1050px) {
    padding: 0;
  }
  tbody {
    // tr {
    //   cursor: pointer;
    // }
  }

  th {
    background: #cccccc30;
    font-weight: 600;
  }

  th,
  td {
    padding: 8px 15px;
    white-space: nowrap;
    vertical-align: middle;
    text-align: center;
    &:first-child {
      text-align: left;
    }
  }

  .icon {
    max-width: 18px;
    color: #ff0000;
  }
}

.trades-details-modal {
  @media screen and (max-width: 1200px) {
    max-width: 900px;
  }
  @media screen and (max-width: 992px) {
    max-width: 750px;
    margin: 0.5rem auto;
  }
  @media screen and (max-width: 767px) {
    max-width: 100%;
    margin: 0.5rem;
  }
  .modal-title {
    width: 100%;
    font-size: 18px;
  }
  p {
    font-size: 15px;
    color: #000;
  }
}

.tradeLog-details {
  border: 1px solid #d2e7e3;
  border-radius: 9px;
  margin-bottom: 25px;

  h5 {
    padding: 10px 15px;
  }
}
.trade-log-no {
  max-height: 340px;
  overflow-y: auto;
}
.tradelog-list {
  background: #f6fcfb;
  padding: 10px 15px;
  border-radius: 8px;
  max-height: 310px;
  overflow-y: auto;
  @media screen and (max-width: 767px) {
    padding: 10px;
  }
  p {
    margin-bottom: 3px;
  }
  span {
    font-size: 15px;
    display: block;
  }
}
.tradelog-item {
  margin-bottom: 5px;
}
.toggle-switch {
  @mixin can-toggle-branding(
    $can-toggle-off-color: #ea0030,
    $can-toggle-on-color: #5fc054,
    $can-toggle-inactive-text: rgba(white, 0.5),
    $can-toggle-transition: cubic-bezier(0, 1, 0.5, 1)
  ) {
    input[type='checkbox'] {
      &[disabled] ~ label {
        color: rgba($can-toggle-off-color, 0.5);
      }

      &:focus ~ label,
      &:hover ~ label {
        .can-toggle__switch {
          background-color: #20a466;

          &:after {
            color: #6e6e6e;
          }
        }
      }

      &:hover ~ label {
        color: #20a466;
      }

      &:checked {
        ~ label {
          &:hover {
            color: #cacaca;
          }

          .can-toggle__switch {
            background-color: #cacaca;

            &:after {
              color: #cacaca;
            }
          }
        }

        &:focus,
        &:hover {
          ~ label {
            .can-toggle__switch {
              background-color: #cacaca;

              &:after {
                color: #cacaca;
              }
            }
          }
        }
      }
    }

    label {
      .can-toggle__label-text {
        flex: 1;
      }

      .can-toggle__switch {
        transition: background-color 0.3s $can-toggle-transition;
        background: #20a466;

        &:before {
          color: #fff;
        }

        &:after {
          // Autoprefixer choked here, so making the prefixes explicit
          -webkit-transition: -webkit-transform 0.3s $can-toggle-transition;
          transition: transform 0.3s $can-toggle-transition;
          color: #6e6e6e;
        }
      }
    }
  }

  @mixin can-toggle-appearance(
    $can-toggle-width: 134px,
    $can-toggle-height: 30px,
    $can-toggle-border-radius: 4px,
    $can-toggle-offset: 2px,
    $can-toggle-label-font-size: 14px,
    $can-toggle-switch-font-size: 12px,
    $can-toggle-shadow: 0 3px 3px rgba(black, 0.4)
  ) {
    $can-toggle-switch-width: $can-toggle-width/2;

    input[type='checkbox'] {
      // &:focus ~ label, &:hover ~ label {
      //   .can-toggle__switch {
      //     &:after { box-shadow: $can-toggle-shadow; }
      //   }
      // }

      &:checked {
        ~ label {
          .can-toggle__switch {
            &:after {
              transform: translate3d(
                $can-toggle-width -
                  ($can-toggle-switch-width + $can-toggle-offset),
                0,
                0
              );
            }
          }
        }

        // &:focus, &:hover {
        //   ~ label {
        //     .can-toggle__switch { &:after { box-shadow: $can-toggle-shadow; } }
        //   }
        // }
      }
    }

    label {
      font-size: $can-toggle-label-font-size;

      .can-toggle__switch {
        height: $can-toggle-height;
        flex: 0 0 $can-toggle-width;
        border-radius: 5px;

        &:before {
          left: $can-toggle-width/2;
          font-size: $can-toggle-switch-font-size;
          line-height: $can-toggle-height;
          width: $can-toggle-width/2;
          padding: 0 12px;
        }

        &:after {
          top: $can-toggle-offset;
          left: $can-toggle-offset;
          border-radius: 5px;
          width: $can-toggle-switch-width - $can-toggle-offset;
          line-height: $can-toggle-height - ($can-toggle-offset * 2);
          font-size: $can-toggle-switch-font-size;
        }

        // &:hover {
        //   &:after { box-shadow: $can-toggle-shadow; }
        // }
      }
    }
  }

  .can-toggle {
    position: relative;
    max-width: 135px;
    *,
    *:before,
    *:after {
      box-sizing: border-box;
    }

    //overflow: hidden;

    input[type='checkbox'] {
      opacity: 0;
      position: absolute;
      top: 0;
      left: 0;

      &[disabled] ~ label {
        pointer-events: none;

        .can-toggle__switch {
          opacity: 0.4;
        }
      }

      &:checked {
        ~ label {
          .can-toggle__switch {
            &:before {
              content: attr(data-checked);
              left: 0;
            }

            &:after {
              content: attr(data-unchecked);
            }
          }
        }

        &:focus,
        &:hover {
          ~ label {
          }
        }
      }
    }

    label {
      user-select: none;
      position: relative;
      display: flex;
      align-items: center;

      .can-toggle__label-text {
        flex: 1;
        padding-left: 32px;
      }

      .can-toggle__switch {
        position: relative;

        &:before {
          content: attr(data-unchecked);
          position: absolute;
          top: 0;
          text-transform: uppercase;
          text-align: center;
        }

        &:after {
          content: attr(data-checked);
          position: absolute;
          z-index: 5;
          text-transform: uppercase;
          text-align: center;
          background: white;
          transform: translate3d(0, 0, 0);
        }
      }
    }

    @include can-toggle-branding;
    @include can-toggle-appearance;

    &.can-toggle--size-small {
      @include can-toggle-appearance(
        90px,
        // Toggle width
        28px,
        // Toggle height
        2px,
        // Toggle border radius
        1px,
        // Offset (distance btw switch and box)
        13px,
        // Label font size
        10px,
        // Switch font size
        0 2px 2px rgba(black, 0.4) // Switch shadow on hover/focus
      );
    }
  }
}
.date-picker-search {
  display: flex;
  align-items: center;
  gap: 10px;
  @media screen and (max-width: 767px) {
    flex-wrap: wrap;
    width: 100%;
  }
  .ant-space {
    @media screen and (max-width: 767px) {
      // flex-wrap: wrap;
      width: 100%;
    }
  }
  input {
    width: 40%;
    @media screen and (max-width: 767px) {
      width: 100%;
    }
  }
}
.datepicker-block {
  display: flex;
  align-items: center;
  gap: 10px;
  width: 60%;
  @media screen and (max-width: 767px) {
    width: 100%;
  }
  .ant-space-item {
    @media screen and (max-width: 767px) {
      width: 50%;
    }
  }
}

/* Ensure picker takes full width in mobile view */
.daterange-picker .ant-picker {
  width: 100%;
}

/* Customize the popup on mobile */
@media (max-width: 768px) {
  .daterange-picker .ant-picker {
    font-size: 14px; /* Smaller font for mobile */
  }

  .daterange-picker .ant-picker-input {
    padding: 8px; /* Reduce padding for mobile */
  }

  .daterange-picker .ant-picker-dropdown {
    width: 100vw; /* Make the calendar width fit the viewport width */
    left: 0 !important; /* Align the calendar to the left of the screen */
    right: 0 !important; /* Align the calendar to the right of the screen */
    top: auto !important; /* Prevent overlap by adjusting top/bottom */
  }

  /* Adjust the calendar cells for better mobile viewing */
  .daterange-picker .ant-picker-cell {
    font-size: 12px; /* Reduce calendar font size for mobile */
  }
}

// .daterange-picker {
//   width: 100%;
// }

// .daterange-picker .ant-picker {
//   padding: 7px 12px;
//   @media screen and (max-width: 767px) {
//     width: 100%;
//   }
// }

.filterIcon {
  position: relative;

  img {
    max-width: 20px;
    height: 20px;
  }
}

.filtercounter {
  position: absolute;
  width: 17px;
  height: 17px;
  background: #f9e5c8;
  border-radius: 50px;
  border: 1px solid #f9bc63;
  color: #f98163;
  top: -6px;
  right: -10px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 14px;
}
// .full-width-header {
//   .header-main {
//     width: 100%;
//   }
//   .table-container {
//     margin-top: auto;
//     padding-top: 85px;
//     height: auto;
//   }
// }
.filter-dropdown {
  .dropdown-item {
    padding: 5px 15px;
  }
  .filter-toggle-btn {
    border: 0;
    background: none;
    padding: 0;
    &::after {
      content: none;
    }
  }
}
.search-feild {
  border: 1px solid #dee2e6;
  border-radius: 0.375rem;
  padding: 0.375rem 0.75rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  @media screen and (max-width: 767px) {
    width: 100%;
  }
  input {
    border: 0;
    padding: 0;
    width: 100%;
    &:focus {
      box-shadow: none;
    }
    &::placeholder {
      color: #c0c0c0;
      font-weight: 200;
    }
  }
  img {
    max-width: 20px;
  }
}
.filter-icon {
  color: #448b56;
  // color: #636864;
}
.filter-disable-icon {
  // color: #6d7972;
  color: #d0d0d0;
}
