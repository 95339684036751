.accounts-lists {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.green {
  color: #448b56 !important;
}

.yellow {
  color: #ffc626 !important;
}

.red {
  color: #ff2e48 !important;
}

.accounts-list {
  .card-header {
    background: #253944;
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 15px;

    .FA-heading {
      font-size: 18px;
      color: #fff;
      font-weight: 500;
    }
  }

  .card-content {
    padding: 15px;

    .FA-details {
      display: flex;
      gap: 25px;
      flex-wrap: wrap;
      margin-bottom: 20px;

      @media screen and (max-width: 767px) {
        row-gap: 10px;
        margin-bottom: 15px;
      }
    }

    .status-btns {
      display: flex;
      align-items: center;
      gap: 15px;
     

      @media screen and (max-width: 767px) {
        margin-top: 15px;
      }

      @media screen and (max-width: 992px) {
        margin-top: 15px;
        flex-wrap: wrap;
        flex-direction: row;
      }

      .btn {
        font-size: 14px;
        padding: 6px 10px;
        min-width: 135px;

        &:focus-visible {
          background-color: transparent;
        }
      }
    }
  }
}

.consistency {
  position: relative;
}

.consistency-value {
  position: absolute;
  display: flex;
  justify-content: space-around;
  align-items: center;
  width: 100%;
  top: 50%;
  transform: translateY(-50%);
  color: #fff;
  height: 100%;

  .con-value {
    font-size: 12px;
  }
}

.Initiate-text {
  font-size: 13px;
  line-height: normal;
  font-weight: 600;
  color: var(--dark-text);
  margin: 5px 0;
  @media screen and (max-width:992px) {
    margin-bottom: 15px;
  }
}

// .Alert-box {
//   background: #ffefc2;
//   border-radius: 10px;
//   padding: 10px;
//   max-width: 270px;
//   width: 100%;
//   font-size: 14px;
//   line-height: normal;
//   margin: 0 0 0 auto;
// }
// .heading-right {
//   font-size: 14px;
//   color: #fff;
//   margin: 0;
//   line-height: normal;
//   display: flex;
//   gap: 5px;
//   align-items: center;
//   justify-content: end;
//   width: 100%;
// }
// .activecircle {
//   background-color: #029d53;
//   width: 10px;
//   height: 10px;
//   border-radius: 20px;
// }
// .inactive {
//   background-color: #ff2e48;
//   width: 10px;
//   height: 10px;
//   border-radius: 20px;
// }
// .ai-analysis {
//   background-color: #ffc626;
//   width: 10px;
//   height: 10px;
//   border-radius: 20px;
// }

// .........toggle switches
.monthly-yearly-toggle-block {
  max-width: 175px;
  width: 100%;
  margin: 15px auto;
  position: relative;

  .pricing-switcher {
    $button-height: 40px;
    $animation-time: 0.3s;
    text-align: center;

    .fieldset {
      display: inline-block;
      position: relative;
      border-radius: 50em;
      background-color: lightgray;
      max-width: 100%;
      width: 100%;
    }

    input[type='radio'] {
      position: absolute;
      opacity: 0;
    }

    label {
      position: relative;
      z-index: 1;
      display: inline-block;
      float: left;
      width: 50%;
      line-height: $button-height;
      cursor: pointer;
      color: #000;
      text-decoration: none;
      font-weight: bold;
      transition-delay: 0.1s;
      transition: color $animation-time ease;
    }

    input[type='radio']:checked+label {
      color: #fff;
      text-decoration: none;
    }

    .switch {
      position: absolute;
      left: 0;
      height: 40px;
      width: 50%;
      background-color: #029d53;
      border-radius: 50em;
      transition: transform 0.3s;
    }

    input[type='radio']:checked+label+.switch,
    input[type='radio']:checked+label:nth-of-type(n)+.switch {
      /* use label:nth-of-type(n) to fix a bug on safari with multiple adjacent-sibling selectors*/
      -webkit-transform: translateX(90px);
      -moz-transform: translateX(90px);
      -ms-transform: translateX(90px);
      -o-transform: translateX(90px);
      transform: translateX(90px);
    }
  }
}

.send-icon {
  color: #448b56;
}

.send-disable-icon {
  color: #d0d0d0;
}

.chat-footer-dropdown {
  padding: 18px 10px 0 10px;

  .form-select {
    padding: 0;
    font-size: 14px;
    background: #283947;
    border: #283947;
    color: #fff;
    background-image: url(../../assets/images/arrow-white.svg);
    background-position: right center;
    padding: 7px 23px 7px 7px;
    background-size: 10px;
    background-repeat: no-repeat;

    &:focus {
      box-shadow: none;
    }
  }
}



.dotsContainer {
  // width: 50px;
  height: 25px;
  // background: #f2f2f2;
  // border-radius: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-top: 5px;

  #dot1,
  #dot2,
  #dot3 {
    width: 6px;
    height: 6px;
    background: #448b56;
    border-radius: 50%;
    margin: 3px;
    transition: all 0.5s ease-in-out;
    animation: typing 1s infinite;
  }

  #dot1 {
    animation-delay: 1s;
  }

  #dot2 {
    animation-delay: 0.5s;
  }

  #dot3 {
    animation-delay: 0.8s;
  }
}

@keyframes typing {
  0% {
    transform: translateY(0);
    transition: all 0.5s ease-in-out;
  }

  50% {
    transform: translateY(-5px);
    transition: all 0.5s ease-in-out;
  }

  100% {
    transform: translateY(0);
    transition: all 0.5s ease-in-out;
  }
}

.default-chatting {
  display: flex;
  align-items: center;
  gap: 15px;
  padding: 8px;
  position: absolute;
  bottom: 0;
  width: 100%;
}

.default-profile {
  width: 28px;
  height: 28px;
  background: #f2f2f2;
  border-radius: 50px;
  display: flex;
  align-items: center;
  justify-content: center;

  img {
    width: 25px;
    height: 25px;
  }
}

.mobile-close-btn {
  @media screen and (min-width:992px) {
    display: none;
  }
}

.subscribe-btn {
  padding-left: 22px;
  padding-right: 22px;
}