.account-analysis {
  padding: 40px 70px;
}
@media screen and (max-width: 1120px) {
  .account-analysis {
    padding: 20px;
  }
}

.account-analysis .table {

  th,
  td {
    font-size: 15px;
    line-height: normal;
  }

  th {
    font-weight: 600;
  }
}

.account-analysis .accordion-body {
  ul li {
    font-size: 15px;

    b {
      font-weight: 600;
    }
  }
}

.bold-text {
  font-weight: 600;
}

.report-headings {
  margin: 0 0 10px 0;
  padding: 15px 0 0 0;
}


.report-list {
  display: flex;
  align-items: center;
  @media screen and (max-width:767px) {
    padding: 6px 0;
  }
}

.report-title {
  font-weight: 600;
  font-size: 16px;
  color: #000;
  min-width: 150px;
}


.report-data {
  font-size: 16px;
  color: #000;
  white-space: nowrap;
  @media (max-width: 767px) {
    // white-space: normal;
  }
  span {
    font-size: .8em;
    margin-left: 4px;
    font-weight: 600;
    @media (max-width: 767px) {
      margin-left: 0;
      display: block;
    }
  }
}
// .report-data {
//   font-weight: 500;
//   font-size: 16px;
//   color: #000;
// }

.analysis-data-section {
  .accordion {
    // max-width: 1000px;
    margin: 0 auto;
  }
  .accordion-button:not(.collapsed) {
    background: #01904b;
    color: #fff;
    font-weight: 600;
    z-index: 0;

  }
  .accordion-button:focus{
    &:focus {
      box-shadow: none;
    }
  }
  .accordion-button:not(.collapsed)::after {
    background-image: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23ffffff'><path fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/></svg>") !important;
  }
  .accordion-body {
  table {
    thead {
      th {
        text-align: center;
        &:first-child {
          text-align: left;
        }
      }
    }
   tbody {
    td {
      text-align: center;
     &:first-child {
      text-align: left;
     }
    }
    th {
      text-align: left;
    }
   }
  }
  }
}
.suggestions {
  background: #d0e7de;
  padding: 20px;
  border-radius: 5px;
  border: 1px solid #98cdb8;
}

.dark-green {
  color: #053623;
}

.suggestions h6 {
  font-weight: 600;
  font-size: 18px;
  margin-bottom: 15px;
}

.suggestions p {
  margin-bottom: 10px;
  line-height: 21px;
  font-size: 15px;
}

.instrument-text li {
  margin-bottom: 10px;
  line-height: 21px;
  font-size: 15px;
}


.alert {
  padding: 20px;
  border: 1px solid #ccc;
  background-color: #f8f8f8;
  text-align: center;
}
.trading-plan-block {
  padding-top: 60px;
  padding-bottom: 40px; 

  @media screen and (max-width:767px) {
  padding-top: 30px;
  padding-bottom: 20px; 
   }
}
.trading-plan-heading {
  font-size: 17px;
  font-weight: 600;
  padding-top:20px ;
  color: #1f1f1f;
}
.trading-plans {
  display: flex;
  justify-content: center;
  gap: 50px;
  margin-bottom: 40px;

  @media screen and (max-width:1050px) {
    gap: 15px;
    }

  @media screen and (max-width:767px) {
   flex-wrap: wrap;
   gap: 35px;
   }
   .form-select {
    color: #006ffa;
    padding: 4px 10px;
    font-weight: 600;
    border: 1px solid #ccc;
    border-radius: 4px;
   }
}
.profit-target {
  min-width: 300px;
  label {
    font-size: 14px;
    font-weight: 700;
    margin-bottom:15px;
  }
}
.trading-plan {
  @media screen and (max-width:992px) {
    padding: 12px;
    }
}

.trading-footer-text {
  font-size: 14px;
  text-align: center;
  max-width: 400px;
  margin: 0 auto 10px;
  font-weight: 600;
  color: #1f1f1f;
}
.analysis-graph canvas {
  max-width: 700px;
  margin: 0 auto;
  height: 310px !important;
  width: 100% !important;
  object-fit: contain;
}
.profit-progress {
  display: flex;
  justify-content: space-between;
  width: 100%;
  border: 1px solid #ccc;
  border-radius: 4px;
  padding: 3px 6px;
  img{
    max-width: 15px;
  }
  input {
    border: 0;
    padding: 0;
    text-align: center;
    &:focus-visible {
      outline: 0;
    }
  }
}
.profit_rate {
  color: #0d6efd;
  font-weight: 600;
  font-size: 14px;
  line-height: 24px;
}
.button-left-right{
border: 0;
background: #ffffff;
}
.analysis-scrollar {
  overflow-y: auto;
  max-height: 265px;
}

.video-instruction-container {
  display: flex;
  justify-content: center; /* Centers the content horizontally */
  align-items: center;     /* Centers the content vertically */
  padding: 20px;           /* Adds padding around the content */
  margin-top: 20px;        /* Optional: Adds space between the steps and the button */
}
