.card-list {
  list-style: none;
  margin: 0;
  padding: 40px 40px;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  @media screen and (max-width:767px) {
   gap: 20px;
   padding: 30px 20px;
  }
  li {
    width: 30%;
    box-shadow: rgba(17, 17, 26, 0.1) 0px 0px 16px;
    border-radius: 8px;
    padding: 24px;
    @media screen and (max-width:767px) {
      width: 100%;
    }
    img {
      width: 140px;
    }
    h2 {
      font-size: 16px;
    }
    h6 {
      font-size: 14px;
      color: #919191;
      margin-top: 4px;
    }
    &:last-child {
      text-align: center;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }

}


.red-deactive {
  color: #fff;
  background: #e03046;
  padding: 5px 13px;
  border-radius: 4px;
  border: 1px solid #e03046;
  display: inline-block;
  line-height: normal;
  font-size: 15px;
  width: 100%;
  max-width: 100px;
}
.blue-active {
  color: #fff;
  background: #0fc46f;
  padding: 5px 13px;
  border-radius: 4px;
  border: 1px solid #0fc46f;
  display: inline-block;
  line-height: normal;
  font-size: 15px;
  max-width: 110px;
  width: 100%;
}
.gray-paused {
  color: #fff;
  background: #808080;
  padding: 5px 13px;
  border-radius: 4px;
  border: 1px solid #808080;
  display: inline-block;
  line-height: normal;
  font-size: 15px;
  max-width: 110px;
  width: 100%;
}

.table-heading {
  font-size: 24px;
  font-weight: 600;
  margin: 0 0 10px 0;
  padding-left: 40px;
  @media screen and (max-width:767px) {
    padding-left: 20px;
  }
}
.Billing-component {
  padding: 20px;
}
.paid {
  color: #20a466;
  background: rgb(179 241 212 / 23%) !important;
  padding: 5px 13px !important;
  border-radius: 4px;
  border: 1px solid #0fc46f73;
  display: inline-block;
  font-size: 15px;
  line-height: normal;
  max-width: 85px;
  width: 100%;
}
.unpaid {
  color: #000;
  background: #e030463b !important;
  padding: 5px 13px !important;
  border-radius: 4px;
  border: 1px solid #e030463b;
  display: inline-block;
  font-size: 15px;
  line-height: normal;
  max-width: 85px;
  width: 100%;
}
.disabled-button {
  /* Style properties to make the button appear disabled */
  background-color: #f0f0f0; /* Change the background color */
  border-color: #d9d9d9; /* Change the border color */
  color: #bfbfbf; /* Change the text color */
  cursor: not-allowed; /* Change cursor to indicate not clickable */
}

.ant-tooltip {
  padding-top: 27px;
}